import { characterService } from "~/services/character";
import type { Character } from "~/types/character"; // Assuming you have a type defined for Character

interface GlobalCharacterState {
  homeCharacters: Character[];
  exploreCharacters: Character[];
}

export const useGlobalCharacterStore = defineStore("globalCharacter", {
  state: (): GlobalCharacterState => ({
    homeCharacters: [],
    exploreCharacters: [],
  }),

  getters: {
    hasHomeCharacters: (state: GlobalCharacterState): boolean => state.homeCharacters?.length > 0,
    hasExploreCharacters: (state: GlobalCharacterState): boolean => state.exploreCharacters?.length > 0,
  },

  actions: {
    // 获取首页角色数据
    async fetchHomeCharacters(): Promise<void> {
      if (this.hasHomeCharacters) return;
      try {
        const res = await characterService.getHomepageCharacters();
        this.homeCharacters = res.data.value.data.characters as Character[];
      } catch (error) {
        console.error("Error fetching home characters:", error);
      }
    },

    // 获取 Explore 页面角色数据
    async fetchExploreCharacters(): Promise<void> {
      if (this.hasExploreCharacters) return;
      try {
        const res = await characterService.getExploreCharacters({ page: 1, size: 100 });
        this.exploreCharacters = res.data.value.data.characters as Character[];
      } catch (error) {
        console.error("Error fetching explore characters:", error);
      }
    },
  },
});
